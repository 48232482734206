import styled from 'styled-components'

import Logo from 'gatsby-theme-atomic-design/src/atoms/Nav/Logo'

const CustomLogo = styled(Logo)`
  height: 34px;
  width: 130px;
  margin: 0;
`

export default CustomLogo
